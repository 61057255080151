export const experiences = [
    {
        id: 1,
        company: "Graphic Era University",
        role: "Product Developer Intern",
        duration: "July 2022 ~ Present",
        description: `Launched production-ready websites for Graphic Era Group, leveraging diverse technologies, including the flagship website <a href="https://geu.ac.in" style="text-decoration: underline;">https://geu.ac.in</a>, resulting in a more visually appealing and engaging website that garnered a 25% increase in traffic and improved user flow.<br/><br/> Development efforts on <a href="https://geimshospital.com" style="text-decoration: underline;">https://geimshospital.com</a>, enabled patients to conveniently schedule appointments online, resulting in an average of 80+ appointments booked via the website. This significantly improved the hospital's operational efficiency and patient satisfaction.<br/><br/>Contributed to the development of a centralized online hub for new students entering the college, including the websites <a href="https://induction.geu.one" style="text-decoration: underline;">https://induction.geu.one</a> and <a href="https://buzz.geu.ac.in" style="text-decoration: underline;">https://buzz.geu.ac.in</a>, streamlining the onboarding process and enabling better student queries resolution.`,
    },
    {
        id: 2,
        company: "Unsweetened Beauty",
        role: "Software Developer Engineer",
        duration: "Jan 2023 ~ Mar 2023",
        description: `Developed a community interaction and sharing app (<a href="https://app.unsweetenedbeauty.com/" style="text-decoration: underline;">https://app.unsweetenedbeauty.com/</a>) for Unsweetened Beauty, enabling users to connect, ask questions, and share experiences about beauty products. Implemented an agile development approach and conducted thorough tests to ensure a seamless user experience. The app increased user engagement, fostered a vibrant community, and supported Unsweetened Beauty's mission of empowering individuals in their skincare choices.<br/><br/>Contributed to Unsweetened Beauty's success by developing a user-friendly community app where beauty enthusiasts could connect, seek advice, and share experiences. Prioritised website sanity and implemented agile methodologies for efficient development. The app enhanced user engagement, empowered informed skincare decisions, and built a loyal customer base.<br/><br/>Empowered Unsweetened Beauty's users with a community interaction and sharing app. Employed agile development practices and rigorous testing to ensure a smooth user experience. The app facilitated real-time discussions, boosted engagement, and supported users in making informed beauty choices.`,
    },
    {
        id: 3,
        company: "Kiikan Analytics",
        role: "Software Developer ",
        duration: "July 2023 ~ Present",
        description: `I played a pivotal role in the development of PlayWallet (<a href="https://www.estudypal.com/" style="text-decoration: underline;">https://playwallet.co/</a>), an innovative wallet-as-a-service platform, redefining the way mobile wallets are created for games. Notably, I led the implementation of a Multi Auth integration dashboard for Game Developers. This dashboard streamlined the wallet embedding process into games, achieving remarkable results. The solution reduced integration time from days to just 24 hours, delivering unparalleled efficiency for developers and a significant enhancement in user experience. <br/> <br/>
        My contributions to Knotify (<a href="https://knotify.vercel.app" style="text-decoration: underline;">https://playwallet.co/</a>), an advanced tool designed for the deskless workforce, were instrumental in driving operational improvements. By automating the hiring process from application to day one, we achieved a remarkable reduction in onboarding time and resources. Furthermore, the implementation of training and announcements features led to a substantial increase in employee engagement, resulting in a 15% reduction in churn rates. Additionally, Knotify's Knowledge Base feature significantly decreased errors and overhead, leading to a 20% reduction in support ticket volume.
        <br/> <br/>
My role at Kiikan Analytics was marked by significant achievements in these projects, driving efficiency, user experience enhancements, and substantial improvements in operational metrics.        `,
    },
    {
        id: 4,
        company: "EstudyPal",
        role: "Hybrid Designer / Developer",
        duration: "April 2022 - Nov 2022",
        description: `Designed and developed a React + PHP framed citation generator tool for Estudypal (<a href="https://www.estudypal.com/" style="text-decoration: underline;">https://www.estudypal.com/</a>), an organization offering citation tools, proofreading services, assignment writing, and plagiarism checking. The citation generator tool provided users with a convenient and efficient way to generate accurate citations for their academic work, enhancing their writing experience and ensuring academic integrity.<br/><br/>To create the citation generator tool, I utilized the React and PHP frameworks, leveraging their capabilities to deliver a seamless user experience. By implementing intuitive user interfaces and integrating comprehensive citation rules, the tool offered users a reliable solution for generating citations across various citation styles, such as APA, MLA, and Chicago.<br/><br/>The citation generator tool contributed to Estudypal's mission of supporting students and academics in their research and writing endeavors. By simplifying the citation process and promoting proper referencing practices, the tool empowered users to focus on their content creation while ensuring academic excellence.`,
    },
    {
        id: 5,
        company: "Saaspect",
        role: "React Redux Developer",
        duration: "Aug 2021 - Jun 2022",
        description: `At Saaspect, I developed a wide range of Control Management Systems (CMS) catering to different levels of administrators. By analyzing requirements and utilizing my expertise, I designed, developed, and implemented software applications for multiple websites, including <a href="https://www.toodecimal.com/" style="text-decoration: underline;">https://www.toodecimal.com/</a>, <a href="https://adsolut.in/" style="text-decoration: underline;">https://adsolut.in/</a>, and various CMS projects.<br/><br/>Throughout the development process, I ensured a thorough understanding of the clients' needs and incorporated their specific requirements into the software applications. By leveraging my technical skills and industry knowledge, I successfully delivered CMS solutions that streamlined administrative tasks and improved overall efficiency.<br/><br/>Working on projects such as <a href="https://www.toodecimal.com/" style="text-decoration: underline;">https://www.toodecimal.com/</a> and <a href="https://adsolut.in/" style="text-decoration: underline;">https://adsolut.in/</a>, I contributed to the creation of visually appealing and user-friendly websites. These websites provided a seamless user experience and effectively met the objectives of the respective businesses.<br/><br/>Overall, my contributions at Saaspect involved developing diverse Control Management Systems and delivering high-quality software applications for multiple websites, including <a href="https://www.toodecimal.com/" style="text-decoration: underline;">https://www.toodecimal.com/</a> and <a href="https://adsolut.in/" style="text-decoration: underline;">https://adsolut.in/</a>. By combining analytical thinking, design expertise, and technical proficiency, I ensured the successful implementation of these projects.`,
    },
];

export const achievements = [
    {
        id: 1,
        achievement: "Flipkart Grid 4.0 Finalist",
        organization: "Flipkart Hackathon",
        duration: "Jan 2023 ~ Mar 2023",
        description:
            "My most significant professional achievement to date was being selected for participation in Flipkart Grid 4.0, an esteemed international hackathon held in India and the USA. Out of a staggering pool of 1.6 lakh participants, our team's groundbreaking project, an NFT-based warranty system, stood out and gained recognition.        <br/><br/>Our achievement lies in the development of an innovative E-commerce platform that utilizes NFTs as warranty cards to authenticate day-to-day products for end-users across various E-commerce platforms. The delivered product demonstrated simplicity, robustness, and platform independence, offering a practical and effective solution for businesses and users alike.        <br/><br/>Securing a position among the top competitors in Flipkart Grid 4.0 is a testament to our team's exceptional skills, dedication, and ability to tackle real-world challenges. The recognition received in this prestigious hackathon highlights our expertise in problem-solving, collaboration, and innovation.        <br/><br/>By leveraging the power of blockchain technology and NFTs, we addressed the crucial issue of product authenticity in the E-commerce industry, enhancing consumer trust and elevating the overall online shopping experience. Our selection among the vast pool of participants further validates the ingenuity and viability of our NFT-based warranty system.            ",
    },
    {
        id: 2,
        achievement: "Operations & Tech Team",
        organization: "TEDxGraphicEraUniversity",
        duration: "July 2022 ~ Present",
        description: `At our college, we had the honor of organizing a TEDx event, and I had the privilege of leading the development team in creating and managing a fully-fledged ticketing system and its accompanying scanner. Our primary goal was to provide a seamless experience for the attendees, and we achieved this by designing and implementing an efficient ticketing solution. Through meticulous planning and collaboration, we successfully streamlined the registration process, allowing participants to easily register, receive tickets, and gain entry to the event. 
        <br/><br/>
        Moreover, I had the opportunity to create a captivating motion graphics video for the TEDx event. This video was strategically screened across various displays, including the official site, a dedicated Instagram page, and a dedicated YouTube channel. By employing visual storytelling techniques and leveraging motion graphics, I successfully captured the essence of the event, generating excitement among the target audience.
        <br/><br/>
        In summary, my involvement in the development of the ticketing system, along with my contributions to the UI/UX design of the event website and the creation of a captivating motion graphics video, played a crucial role in the success of the TEDx event
        
        `,
    },

    {
        id: 3,
        achievement: "National Finalist Yamaha Explore AI 2.0",
        organization: "Yamaha | Chitkara University",
        duration: "April 2022 - Nov 2022",
        description:
            "I had the privilege of participating in the prestigious Yamaha Explore AI 2.0 event held at Chitkara University. As part of the event, our team undertook the exciting challenge of building a machine learning and AI-based Mood Detection App.      <br/> <br/>      Our app aimed to provide users with a personalized and intuitive interface, offering an efficient and seamless user experience. By leveraging advanced machine learning algorithms, we developed a system capable of detecting and tracking the emotions and moods of individuals. The app not only captured the motion of a person but also saved and analyzed the data over a period, presenting users with an overview of their mood changes and providing personalized recommendations. <br/> <br/>          Our team's dedication and expertise in AI and machine learning enabled us to create an innovative solution that could enhance users' well-being. By offering real-time mood detection and personalized suggestions, our app had the potential to contribute to mental health and overall emotional well-being. ",
    },
];
