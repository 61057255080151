import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "../FeedBack/feedback.scss";
const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

const modal = {
    hidden: { y: "100vh", opacity: 0 },
    visible: {
        y: "190px",
        opacity: 1,
        transition: { delay: 0.5 },
    },
};
const ContactConfirmation = ({ showModal, setShowModal, name }) => {
    console.log(name);
    const navigate = useNavigate();

    return (
        <AnimatePresence>
            {showModal && (
                <motion.div
                    className="backdrop"
                    variants={backdrop}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                >
                    <motion.div
                        variants={modal}
                        className="modal-container-gradient | confirmation-modal"
                    >
                        <div className="modal-container">
                            <div className="modal-heading">
                                <h1>
                                    Thanks {name} for reaching out to me 😁✌🏻
                                </h1>
                            </div>
                            <div className="modal-subtitle">
                                <p>
                                    You’ll receive a mail in few seconds
                                    regarding the further process.
                                </p>
                            </div>

                            <div className="modal-btns">
                                <button
                                    onClick={() => {
                                        setShowModal(false);
                                        navigate("/");
                                    }}
                                >
                                    Continue With the portfolio 🤙🏻
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ContactConfirmation;
