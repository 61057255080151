import React, { useState, useRef, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Profile from "../../assets/others/profile.png";
import Heart from "../../assets/others/heart.png";
import Close from "../../assets/icons/close.svg";
import Git from "../../assets/icons/giticon.svg";
import Arrow from "../../assets/icons/arrowtilted.svg";
import LeftArrow from "../../assets/icons/leftarrowkey.svg";
import RightArrow from "../../assets/icons/rightarrowkey.svg";
import Keyboard from "../../assets/icons/keyboard.svg";
import SwipeRight from "../../assets/icons/leftswipe.svg";
import SwipeLeft from "../../assets/icons/rightswipe.svg";
import "./ProjectModal.scss";
import { projects } from "../../data/projects";
const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

const modal = {
    hidden: { y: "100vh", opacity: 0 },
    visible: {
        y: "0px",
        opacity: 1,
        transition: { delay: 0.1 },
    },
};
const ProjectModal = ({
    showModal,
    setShowModal,
    projectsdata,
    setprojectsdata,
}) => {
    // console.log(projectsdata?.id);

    //* Find the project with the matching ID
    let foundProject;
    if (projectsdata?.id >= 1 && projectsdata?.id <= 8) {
        foundProject = projects.row1.find(
            (project) => project.id === projectsdata?.id
        );
        // console.log("found in row 1");
    } else if (projectsdata?.id >= 9 && projectsdata?.id <= 16) {
        foundProject = projects.row2.find(
            (project) => project.id === projectsdata?.id
        );
        // console.log("found in row 2");
    } else if (projectsdata?.id >= 17 && projectsdata?.id <= 24) {
        foundProject = projects.row3.find(
            (project) => project.id === projectsdata?.id
        );
        // console.log("found in row 3");
    }

    setprojectsdata(foundProject);
    // console.log(foundProject);

    //* Return the found project or null if not found
    const nextproject = useCallback(() => {
        let nextProject;
        const currentId = projectsdata?.id;
        let currentRow;

        if (currentId >= 1 && currentId <= 8) {
            currentRow = projects.row1;
        } else if (currentId >= 9 && currentId <= 16) {
            currentRow = projects.row2;
        } else if (currentId >= 17 && currentId <= 24) {
            currentRow = projects.row3;
        }

        if (currentRow) {
            const currentIndex = currentRow.findIndex(
                (project) => project.id === currentId
            );
            if (currentIndex !== -1) {
                const nextIndex = currentIndex + 1;
                if (nextIndex < currentRow.length) {
                    nextProject = currentRow[nextIndex];
                } else {
                    // Move to the next row if available
                    if (currentRow === projects.row1) {
                        nextProject = projects.row2[0];
                    } else if (currentRow === projects.row2) {
                        nextProject = projects.row3[0];
                    }
                }
            }
        }

        console.log(nextProject);
        if (nextProject) {
            setprojectsdata(nextProject);
        }
    }, [projectsdata, setprojectsdata]);
    const prevproject = useCallback(() => {
        let prevProject;
        const currentId = projectsdata?.id;
        let currentRow;

        if (currentId >= 2 && currentId <= 9) {
            currentRow = projects.row1;
        } else if (currentId >= 10 && currentId <= 17) {
            currentRow = projects.row2;
        } else if (currentId >= 18 && currentId <= 25) {
            currentRow = projects.row3;
        }

        if (currentRow) {
            prevProject = currentRow.find(
                (project) => project.id === currentId - 1
            );
        }

        if (prevProject) {
            setprojectsdata(prevProject);
        }
    }, [projectsdata, setprojectsdata]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.keyCode === 39) {
                nextproject();
            }
            if (e.keyCode === 37) {
                prevproject();
            }
        };
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [projectsdata?.id, nextproject, prevproject]);

    const [touchStartX, setTouchStartX] = useState(0);
    const [touchStartY, setTouchStartY] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);
    const [touchEndY, setTouchEndY] = useState(0);

    const modalRef = useRef(null);
    const handleTouchStart = (e) => {
        setTouchStartX(e.touches[0].clientX);
        setTouchStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        setTouchEndX(e.touches[0].clientX);
        setTouchEndY(e.touches[0].clientY);
    };

    const handleTouchEnd = () => {
        const touchDiffX = touchEndX - touchStartX;
        const touchDiffY = touchEndY - touchStartY;

        // Check for horizontal swipe (left or right)
        if (Math.abs(touchDiffX) > Math.abs(touchDiffY)) {
            if (touchDiffX > 50) {
                // Swipe right (move to previous project)
                prevproject();
            } else if (touchDiffX < -50) {
                // Swipe left (move to next project)
                nextproject();
            }
        } else {
            // Check for vertical swipe (up or down)

            if (touchDiffY > 20) {
                // Swipe down
                const top12Percent = window.innerHeight * 0.5;

                if (touchStartY <= top12Percent) {
                    // Swipe down from the top part of the screen
                    closeModal();
                }
            }
        }
    };
    const closeModal = () => {
        setShowModal(false);
        setprojectsdata({
            ...projectsdata,
            details: {
                title: "",
                img: "",
                content: "",
            },
        });
    };
    return (
        <div>
            <AnimatePresence>
                {showModal && (
                    <motion.div
                        className="backdrop | project-backdrop"
                        variants={backdrop}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <motion.div
                            variants={modal}
                            className="modal-container-gradient | project-modal"
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                            ref={modalRef}
                        >
                            <div
                                onClick={() => {
                                    setShowModal(false);
                                    setprojectsdata({
                                        ...projectsdata,
                                        details: {
                                            title: "",
                                            img: "",
                                            content: "",
                                        },
                                    });
                                }}
                                className="modal-close-button"
                            >
                                <img src={Close} alt="" />
                            </div>
                            <div className="modal-container">
                                <div className="project-modal-container">
                                    <div className="key-commands">
                                        <div className="key-commands-left">
                                            <p> Prev Project</p>
                                            <div className="key-commands-left-arrow">
                                                <img src={LeftArrow} alt="" />
                                            </div>
                                        </div>
                                        <div className="keyboard">
                                            <img src={Keyboard} alt="" />
                                        </div>
                                        <div className="key-commands-left">
                                            <div className="key-commands-left-arrow">
                                                <img src={RightArrow} alt="" />
                                            </div>
                                            <p> Next Project</p>
                                        </div>
                                    </div>
                                    <div className="key-commands | key-commands-mobile">
                                        <div className="key-commands-left">
                                            <p> Prev Project</p>
                                            <div className="key-commands-left-arrow">
                                                <img src={SwipeRight} alt="" />
                                            </div>
                                        </div>
                                        <div className="keyboard">
                                            <p>Swipe</p>
                                        </div>
                                        <div className="key-commands-left">
                                            <div className="key-commands-left-arrow">
                                                <img src={SwipeLeft} alt="" />
                                            </div>
                                            <p> Next Project</p>
                                        </div>
                                    </div>
                                    <div className="project-modal-profile">
                                        <div className="project-modal-profile-details">
                                            <div className="project-modal-profile-img">
                                                <img src={Profile} alt="" />
                                            </div>
                                            <div className="project-modal-profile-name">
                                                Dhairya Marwah
                                                <p>Developer</p>
                                            </div>
                                        </div>
                                        <div className="project-modal-profile-like">
                                            <img src={Heart} alt="" />
                                        </div>
                                    </div>
                                    <div className="project-details">
                                        <div className="modal-heading">
                                            <h1>
                                                {" "}
                                                {projectsdata?.details?.title}
                                            </h1>
                                        </div>
                                        <div className="modal-desc-img">
                                            <img
                                                src={projectsdata?.details?.img}
                                                alt=""
                                            />
                                        </div>
                                        <div className="project-modal-buttons">
                                            {projectsdata?.details
                                                ?.websiteLink ? (
                                                <>
                                                    <button
                                                        onClick={() => {
                                                            window.open(
                                                                projectsdata
                                                                    ?.details
                                                                    ?.websiteLink,
                                                                "_blank"
                                                            );
                                                        }}
                                                    >
                                                        Live Demo
                                                        <img
                                                            src={Arrow}
                                                            alt=""
                                                        />
                                                    </button>
                                                </>
                                            ) : null}
                                            {projectsdata?.details
                                                ?.sourceCodeLink ? (
                                                <>
                                                    <button
                                                        onClick={() => {
                                                            window.open(
                                                                projectsdata
                                                                    ?.details
                                                                    ?.sourceCodeLink,
                                                                "_blank"
                                                            );
                                                        }}
                                                        className="secondary"
                                                    >
                                                        <img src={Git} alt="" />
                                                        Source Code
                                                    </button>
                                                </>
                                            ) : null}
                                        </div>
                                        <div className="modal-content">
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: foundProject
                                                        ?.details?.content,
                                                }}
                                            ></p>
                                        </div>
                                    </div>
                                    <div className="project-modal-footer">
                                        <div className="project-modal-footer-header">
                                            Crafted By
                                        </div>
                                        <div className="project-modal-footer-profile">
                                            <div className="modal-line"></div>
                                            <div className="project-modal-footer-profile-img">
                                                <img src={Profile} alt="" />
                                            </div>
                                            <div className="modal-line"></div>
                                        </div>
                                        <div className="project-modal-footer-name">
                                            Dhairya Marwah
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ProjectModal;
