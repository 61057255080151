import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Projects from "../pages/Projects";
import Navbar from "../components/Navbar"; 
import Contact from "../pages/Contact";
import Experience from "../pages/Experience";
import Feedback from "../pages/Feedback";
import BottomBar from "../components/BottomBar/BottomBar";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import FeedBack from "../modals/FeedBack/FeedBack";
import ProjectModal from "../modals/ProjectModal/ProjectModal";

export default function Navigation() {
    const [showModal2, setShowModal2] = useState(false);
    const [projectsdata, setprojectsdata] = useState()
    const routes = [
        {
            path: "/",
            exact: true,
            element: <Home />,
            private: false,
        },
        {
            path: "*",
            exact: true,
            element: <PageNotFound />,
            private: true,
        },
        {
            path: "/projects",
            exact: true,
            element: (
                <Projects showModal={showModal2} setShowModal={setShowModal2} projectsdata={projectsdata}
                    setprojectsdata={setprojectsdata}
                />
            ),
            private: false,
            state: { showModal: false },
        },
        {
            path: "/contact",
            exact: true,
            element: <Contact />,
            private: false,
        },
        {
            path: "/experience",
            exact: true,
            element: <Experience />,
            private: false,
        },
        {
            path: "/feedback",
            exact: true,
            element: <Feedback />,
            private: false,
        },
    ];
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowModal(true);
        }, 15000); // Display modal after 20 seconds

        return () => clearTimeout(timeout); // Clean up the timeout on component unmount
    }, []);
    return (
        <>
            <FeedBack showModal={showModal} setShowModal={setShowModal}  />
            <ProjectModal showModal={showModal2} setShowModal={setShowModal2} projectsdata={projectsdata}
                setprojectsdata={setprojectsdata} 
            />
            <div 
                className={showModal2 ? "page-wrap overflowhidden" : "page-wrap"}
            >
                <Navbar showModal={showModal} setShowModal={setShowModal} />
                <Routes>
                    {routes.map((route, index) => (
                        <>
                            <Route
                                key={index}
                                path={route.path}
                                element={route.element}
                            />
                        </>
                    ))}
                </Routes>
            </div>
            <div className="bottom-bar-absolute">
                <BottomBar />
            </div>
        </>
    );
}
