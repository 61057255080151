import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div className="page-404">
            <h1>404</h1>
            <p>Error : Page not found</p>
            <button
                onClick={() => {
                    navigate("/")
                }}
            >
                Got to home <span>→</span>
            </button>
        </div>
    );
};

export default PageNotFound;
