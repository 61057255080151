import React, { useState } from "react"; 
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Avatar1 from "../../assets/icons/avatar1.png";
import Avatar2 from "../../assets/icons/avatar2.png";
import Avatar3 from "../../assets/icons/avatar3.png";
import Avatar4 from "../../assets/icons/avatar4.png";
import "./feedback.scss";
import FeedBackConfirmation from "../FeedBackConfirmation/FeedBackConfirmation";
import { SubmitFeedback } from "../../services/FeedBack/feedback";
const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

const modal = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
        y: "10px",
        opacity: 1,
        transition: { delay: 0.5 },
    },
};
const FeedBack = ({ showModal, setShowModal }) => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState("");
    const [imgType, setImgType] = useState("");
    const [showModal2, setShowModal2] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            name,
            email,
            feedback,
            imgType,
        };
        console.log(payload);
        const res = await SubmitFeedback(payload);
        console.log(res);
        if (res.success) {
            setShowModal((prev) => !prev);
            setShowModal2((prev) => !prev);
        }
        // Perform further actions with the payload, such as sending it to an API
    };
    return (
        <>
            <FeedBackConfirmation
                showModal={showModal2}
                setShowModal={setShowModal2}
                name={name}
            />
            <AnimatePresence>
                {showModal && (
                    <motion.div
                        className="backdrop"
                        variants={backdrop}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <motion.div
                            variants={modal}
                            className="modal-container-gradient"
                        >
                            <div className="modal-container">
                                <div className="modal-heading">
                                    <h1>
                                        Write a Feedback of Portfolio & <br />{" "}
                                        get featured on
                                    </h1>
                                    <div
                                        onClick={() => {
                                            setShowModal((prev) => !prev);
                                            navigate("/feedback");
                                        }}
                                        className="pill"
                                    >
                                        dhairya.dev/feedback
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-inputs">
                                        <div className="modal-input">
                                            <p>What’s your name?</p>
                                            <input
                                                type="text"
                                                placeholder="Your Name"
                                                value={name}
                                                required
                                                onChange={(e) =>
                                                    setName(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="modal-input">
                                            <p>What’s your Email?</p>
                                            <input
                                                type="email"
                                                placeholder="Your Email"
                                                value={email}
                                                required
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="modal-input">
                                            <p>What’s your Feedback?</p>
                                            <textarea
                                                type="message"
                                                placeholder="Your Feedback"
                                                value={feedback}
                                                required
                                                onChange={(e) =>
                                                    setFeedback(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="modal-input">
                                            <p>Choose Avatar</p>
                                            <div className="avatar-selection">
                                                <div className="avatars">
                                                    <img
                                                        src={Avatar1}
                                                        alt=""
                                                        onClick={() =>
                                                            setImgType("type1")
                                                        }
                                                        className={
                                                            imgType === "type1"
                                                                ? "active"
                                                                : ""
                                                        }
                                                    />
                                                    <img
                                                        src={Avatar2}
                                                        alt=""
                                                        onClick={() =>
                                                            setImgType("type2")
                                                        }
                                                        className={
                                                            imgType === "type2"
                                                                ? "active"
                                                                : ""
                                                        }
                                                    />
                                                    <img
                                                        src={Avatar3}
                                                        alt=""
                                                        onClick={() =>
                                                            setImgType("type3")
                                                        }
                                                        className={
                                                            imgType === "type3"
                                                                ? "active"
                                                                : ""
                                                        }
                                                    />
                                                    <img
                                                        src={Avatar4}
                                                        alt=""
                                                        onClick={() =>
                                                            setImgType("type4")
                                                        }
                                                        className={
                                                            imgType === "type4"
                                                                ? "active"
                                                                : ""
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-btns">
                                        <button
                                            type="submit"
                                        // onClick={handleSubmit}
                                        >
                                            Submit and get featured 😁
                                        </button>
                                        <button
                                        type="button"
                                            className="secondary"
                                            onClick={() => {
                                                setShowModal((prev) => !prev);
                                            }}
                                        >
                                            Cancel & continue 😔
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default FeedBack;
