import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGa from "react-ga";
import Dhairya from "../../assets/others/dhairyaImg.png";
import Dhairya2 from "../../assets/others/dhairya.png";
import Works from "../../assets/others/myWorks.png";
import Skills from "../../assets/others/skills.png";
import Signature from "../../assets/others/signature.svg";
import Signature2 from "../../assets/others/signature2.svg";
// import Circle from "../../assets/others/circle.svg";
import Arrow1 from "../../assets/iconanimation/arrow.svg";
import Arrow2 from "../../assets/iconanimation/arrow2.svg";
import ArrowGradient from "../../assets/icons/arrow-gradient.svg";
import DjangoFy from "../../assets/recentProjects/djangofy.svg";
import DjangoFy2 from "../../assets/recentProjects/djangofy2.svg";
import Linkedin from "../../assets/icons/linkedin.svg";
import Git from "../../assets/icons/git.svg";
import Insta from "../../assets/icons/insta.svg";
import Whatsapp from "../../assets/icons/whatsapp.svg";
import "./../../styles/grids/homeGrid/homeGrid.scss";
import Footer from "../../components/Footer";

const HomePage = ({ children }) => (
    <div className="home-page">
        <div className="home-page-grid | max-container mt-rem">{children}</div>
    </div>
);

const GridRow = ({ children }) => (
    <div className="home-page-grid-row">{children}</div>
);

const GridRowCol = ({ children }) => (
    <div className="home-page-grid-row-col">{children}</div>
);

const DeveloperInfo = ({ isMobileView }) => (
    <div className="home-page-card-flex | flex align-center ">
        <div className="home-page-card-flex-col | mr-2rem | dev-img">
            <img src={isMobileView ? Dhairya2 : Dhairya} alt="Dhairya Marwah" />
        </div>
        <div className="home-page-card-flex-col">
            <p className="uppercase">SOFTWARE DEVELOPER</p>
            <h1>
                Dhairya <br /> Marwah.
            </h1>
            <p>I am a Software Developer based in India</p>
        </div>
    </div>
);

const SquareCard = ({ className, children }) => (
    <div className={`square-card | ${className}`}>{children}</div>
);

const SquareCardFooter = ({ children }) => (
    <div className="square-card-footer | flex align-bottom justify-between | mt-1rem">
        {children}
    </div>
);

const HomePageCard = ({ className, children, animate, path, link }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (link !== undefined) {
            window.open(link, "_blank");
        } else {
            if (path !== undefined) {
                navigate(path);
            }
        }
    };

    return (
        <div
            onClick={handleClick}
            className={`home-page-card ${
                animate ? "animate" : ""
            } ${className}`}
        >
            {children}
        </div>
    );
};

const Index = () => {
    const [animateCards, setAnimateCards] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setAnimateCards(true);
    }, []);

    useEffect(() => {
        setAnimateCards(true);
        const handleResize = () => {
            if (window.innerWidth < 1000) {
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Check initial window size

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        ReactGa.pageview(window.location.pathname);
    }, []);

    // const [showModal, setShowModal] = useState(true)

    const marqueeText = `
    <marquee behavior="scroll" direction="left" scrollamount="3">
    I'm actively seeking <b> Developer/Designer</b>
    positions for full-time employment |
    internship opportunities.
    </marquee>
  `;

    return (
        <>
            {!isMobileView ? (
                <>
                    {/* <BottomBar/> */}
                    <HomePage>
                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-1-info-card"
                                    animate={animateCards}
                                >
                                    <DeveloperInfo />
                                </HomePageCard>
                            </GridRowCol>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-2-card | marquee"
                                    animate={animateCards}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: marqueeText,
                                        }}
                                    ></div>
                                    {/* <marquee
                                    behavior="scroll" direction="left"

                                    >
                                        I'm actively seeking Developer/Designer
                                        positions for full-time employment |
                                        internship opportunities.
                                    </marquee> */}
                                </HomePageCard>
                                <div className="home-page-grid-row">
                                    <GridRowCol>
                                        <HomePageCard
                                            className="size-3-card | gradient-card | p-2rem"
                                            animate={animateCards}
                                            link="https://www.linkedin.com/in/dhairyamarwah/"
                                        >
                                            <SquareCard>
                                                <div className="square-card-content | pl-1rem pr-1rem">
                                                    <img
                                                        src={
                                                            isMobileView
                                                                ? Signature2
                                                                : Signature
                                                        }
                                                        alt="Dhairya's Signature"
                                                    />
                                                </div>
                                                <SquareCardFooter>
                                                    <div className="square-card-footer-text">
                                                        <p>MORE ABOUT ME</p>
                                                        <h2>Know More</h2>
                                                    </div>
                                                    <div className="square-card-footer-arrow animated-arrow gradient-animated-arrow">
                                                        <img
                                                            src={Arrow2}
                                                            alt="Arrow"
                                                        />
                                                    </div>
                                                </SquareCardFooter>
                                            </SquareCard>
                                        </HomePageCard>
                                    </GridRowCol>
                                    <GridRowCol>
                                        <HomePageCard
                                            className="size-3-card | p-2rem"
                                            animate={animateCards}
                                            path={"/projects"}
                                        >
                                            <SquareCard className="gradient-card">
                                                <div className="square-card-content | pl-1rem pr-1rem">
                                                    <div className="works-header">
                                                        MY WORKS
                                                    </div>
                                                    <img
                                                        className="my-works-image"
                                                        src={Works}
                                                        alt="Works"
                                                    />
                                                </div>
                                                <SquareCardFooter>
                                                    <div className="square-card-footer-text">
                                                        <p>SHOWCASE</p>
                                                        <h2>My Projects</h2>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            navigate(
                                                                "/projects"
                                                            );
                                                        }}
                                                        className="square-card-footer-arrow animated-arrow"
                                                    >
                                                        <img
                                                            src={Arrow1}
                                                            alt="Arrow"
                                                        />
                                                    </div>
                                                </SquareCardFooter>
                                            </SquareCard>
                                        </HomePageCard>
                                    </GridRowCol>
                                </div>
                            </GridRowCol>
                        </GridRow>

                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-3-card | p-2rem"
                                    animate={animateCards}
                                    link={"https://djangofy.vercel.app/"}
                                >
                                    <SquareCard>
                                        <div className="square-card-content | pt-1rem">
                                            <img
                                                src={DjangoFy}
                                                alt="DjangoFy"
                                            />
                                        </div>
                                        <SquareCardFooter>
                                            <div className="square-card-footer-text">
                                                <p>CHECKOUT</p>
                                                <h2>DjangoFy</h2>
                                            </div>
                                            <div className="square-card-footer-arrow animated-arrow">
                                                <img src={Arrow1} alt="Arrow" />
                                            </div>
                                        </SquareCardFooter>
                                    </SquareCard>
                                </HomePageCard>
                            </GridRowCol>

                            <GridRowCol>
                                <HomePageCard
                                    className="size-4-card | p-2rem | skills-card"
                                    animate={animateCards}
                                >
                                    <div className="skills-img">
                                        <img src={Skills} alt="" />
                                        <img src={Skills} alt="" />
                                    </div>
                                    <div className="layer"></div>
                                    <SquareCardFooter>
                                        <div className="square-card-footer-text">
                                            <p>TECH STACK</p>
                                            <h2>My Skills</h2>
                                        </div>
                                        <div className="square-card-footer-arrow animated-arrow">
                                            <img src={Arrow1} alt="Arrow" />
                                        </div>
                                    </SquareCardFooter>
                                </HomePageCard>
                            </GridRowCol>

                            <GridRowCol>
                                <HomePageCard
                                    className="size-3-card | p-1rem"
                                    animate={animateCards}
                                >
                                    <SquareCard>
                                        <div className="square-card-content">
                                            <div className="square-card-content-social">
                                                <div className="square-card-content-social-icons | ">
                                                    <a
                                                        href="https://www.linkedin.com/in/dhairyamarwah/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            src={Linkedin}
                                                            alt="Linkedin"
                                                        />
                                                    </a>
                                                    <a
                                                        href="https://github.com/DhairyaMarwah"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            src={Git}
                                                            alt="Github"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pl-1rem pr-1rem pb-1rem">
                                            <SquareCardFooter>
                                                <div className="square-card-footer-text">
                                                    <p>STAY CONNECTED</p>
                                                    <h2>Profiles</h2>
                                                </div>
                                                <div className="square-card-footer-arrow animated-arrow">
                                                    <img
                                                        src={Arrow1}
                                                        alt="Arrow"
                                                    />
                                                </div>
                                            </SquareCardFooter>
                                        </div>
                                    </SquareCard>
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>

                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-4-card | p-2rem | experience-home-card"
                                    animate={animateCards}
                                    // path={"/experience"}
                                >
                                    <div className="new-experience-cards">
                                        <div
                                            onClick={() => {
                                                navigate("/experience");
                                            }}
                                            className="new-experience-card"
                                        >
                                            <div className="new-experience-card-desc">
                                                Years of <br /> Experience
                                            </div>
                                            <div className="new-experience-card-header">
                                                03{" "}
                                                <div className="square-card-footer-arrow animated-arrow">
                                                    <img
                                                        src={Arrow1}
                                                        alt="Arrow"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => {
                                                navigate("/projects");
                                            }}
                                            className="new-experience-card | border-left"
                                        >
                                            <div className="line"></div>
                                            <div className="new-experience-card-desc">
                                                No. of Live <br /> Websites
                                            </div>
                                            <div className="new-experience-card-header">
                                                42{" "}
                                                <div className="square-card-footer-arrow animated-arrow">
                                                    <img
                                                        src={Arrow1}
                                                        alt="Arrow"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => {
                                                navigate("/projects");
                                            }}
                                            className="new-experience-card"
                                        >
                                            <div className="line"></div>
                                            <div className="new-experience-card-desc">
                                                No. of Satisfied <br />
                                                Clients
                                            </div>
                                            <div className="new-experience-card-header">
                                                10
                                                <div className="square-card-footer-arrow animated-arrow">
                                                    <img
                                                        src={Arrow1}
                                                        alt="Arrow"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <SquareCardFooter>
                                        <div className="square-card-footer-text">
                                            <p>WORK EXPERIENCE</p>
                                            <h2>My Experiences</h2>
                                        </div>
                                        <div className="square-card-footer-arrow animated-arrow">
                                            <img src={Arrow1} alt="Arrow" />
                                        </div>
                                    </SquareCardFooter>
                                </HomePageCard>
                            </GridRowCol>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-4-card | p-2rem"
                                    animate={animateCards}
                                    path={"/contact"}
                                >
                                    <div className="arrow-absolute">
                                        <img src={ArrowGradient} alt="Arrow" />
                                    </div>
                                    <div className="work-together-text-flex | flex justify-between">
                                        <div className="work-together-text">
                                            Let’s work <br />{" "}
                                            <span> together.</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                navigate("/contact");
                                            }}
                                            className="square-card-footer-arrow animated-arrow"
                                        >
                                            <img src={Arrow1} alt="Arrow" />
                                        </div>
                                    </div>
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>
                    </HomePage>
                </>
            ) : (
                <>
                    <HomePage>
                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-2-card | marquee"
                                    animate={animateCards}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: marqueeText,
                                        }}
                                    ></div>
                                    {/* <div className="marquee-content">
                                        <div>
                                            <span>
                                                LATEST WORK AND <b>FEATURED</b>{" "}
                                                <img
                                                    src={Circle}
                                                    alt="Circle"
                                                />{" "}
                                                LATEST WORK AND <b>FEATURED</b>{" "}
                                                <img
                                                    src={Circle}
                                                    alt="Circle"
                                                />{" "}
                                                LATEST WORK AND <b>FEATURED</b>{" "}
                                                <img
                                                    src={Circle}
                                                    alt="Circle"
                                                />{" "}
                                                LATEST WORK AND <b>FEATURED</b>{" "}
                                                LATEST WORK AND{" "}
                                                <img
                                                    src={Circle}
                                                    alt="Circle"
                                                />{" "}
                                                LATEST WORK AND <b>FEATURED</b>{" "}
                                                LATEST WORK AND{" "}
                                                <img
                                                    src={Circle}
                                                    alt="Circle"
                                                />
                                            </span>
                                        </div>
                                    </div> */}
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>

                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-1-info-card"
                                    animate={animateCards}
                                >
                                    <DeveloperInfo
                                        isMobileView={isMobileView}
                                    />
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>

                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-3-card | gradient-card | p-2rem"
                                    animate={animateCards}
                                    link="https://www.linkedin.com/in/dhairyamarwah/"
                                >
                                    <SquareCard>
                                        <div className="square-card-content | pl-1rem pr-1rem">
                                            <img
                                                src={
                                                    isMobileView
                                                        ? Signature2
                                                        : Signature
                                                }
                                                alt="Dhairya's Signature"
                                            />
                                        </div>
                                        <SquareCardFooter>
                                            <div className="square-card-footer-text">
                                                <p>MORE ABOUT ME</p>
                                                <h2>Know More</h2>
                                            </div>
                                            <div className="square-card-footer-arrow animated-arrow gradient-animated-arrow">
                                                <img src={Arrow2} alt="Arrow" />
                                            </div>
                                        </SquareCardFooter>
                                    </SquareCard>
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>

                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-3-card | size-3-card-square | p-2rem"
                                    animate={animateCards}
                                    path={"/projects"}
                                >
                                    <SquareCard className="gradient-card">
                                        <div className="square-card-content | pl-1rem pr-1rem">
                                            <div className="works-header">
                                                MY WORKS
                                            </div>
                                            <img
                                                className="my-works-image"
                                                src={Works}
                                                alt="Works"
                                            />
                                        </div>
                                        <SquareCardFooter>
                                            <div className="square-card-footer-text">
                                                <p>SHOWCASE</p>
                                                <h2>My Projects</h2>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    navigate("/projects");
                                                }}
                                                className="square-card-footer-arrow animated-arrow"
                                            >
                                                <img src={Arrow1} alt="Arrow" />
                                            </div>
                                        </SquareCardFooter>
                                    </SquareCard>
                                </HomePageCard>
                            </GridRowCol>

                            <GridRowCol>
                                <HomePageCard
                                    className="size-3-card | size-3-card-square | p-2rem"
                                    animate={animateCards}
                                    link={"https://djangofy.vercel.app/"}
                                >
                                    <SquareCard>
                                        <div className="square-card-content | pt-1rem">
                                            <img
                                                className="recent-image"
                                                src={
                                                    isMobileView
                                                        ? DjangoFy2
                                                        : DjangoFy
                                                }
                                                alt="DjangoFy"
                                            />
                                        </div>
                                        <SquareCardFooter>
                                            <div className="square-card-footer-text">
                                                <p>CHECKOUT</p>
                                                <h2>DjangoFy</h2>
                                            </div>
                                            <div className="square-card-footer-arrow animated-arrow">
                                                <img src={Arrow1} alt="Arrow" />
                                            </div>
                                        </SquareCardFooter>
                                    </SquareCard>
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>

                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-4-card | p-2rem | experience-home-card"
                                    animate={animateCards}
                                >
                                    <div className="new-experience-cards">
                                        <div
                                            onClick={() => {
                                                navigate("/experience");
                                            }}
                                            className="new-experience-card"
                                        >
                                            <div className="new-experience-card-desc">
                                                Years of <br /> Experience
                                            </div>
                                            <div className="new-experience-card-header">
                                                03{" "}
                                                <div className="square-card-footer-arrow animated-arrow">
                                                    <img
                                                        src={Arrow1}
                                                        alt="Arrow"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => {
                                                navigate("/projects");
                                            }}
                                            className="new-experience-card | border-left"
                                        >
                                            <div className="line"></div>
                                            <div className="new-experience-card-desc">
                                                No. of Live <br /> Websites
                                            </div>
                                            <div className="new-experience-card-header">
                                                42{" "}
                                                <div className="square-card-footer-arrow animated-arrow">
                                                    <img
                                                        src={Arrow1}
                                                        alt="Arrow"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => {
                                                navigate("/projects");
                                            }}
                                            className="new-experience-card"
                                        >
                                            <div className="line"></div>
                                            <div className="new-experience-card-desc">
                                                No. of <br />
                                                Clients
                                            </div>
                                            <div className="new-experience-card-header">
                                                10
                                                <div className="square-card-footer-arrow animated-arrow">
                                                    <img
                                                        src={Arrow1}
                                                        alt="Arrow"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <SquareCardFooter>
                                        <div className="square-card-footer-text">
                                            <p>WORK EXPERIENCE</p>
                                            <h2>My Experiences</h2>
                                        </div>
                                        <div className="square-card-footer-arrow animated-arrow">
                                            <img src={Arrow1} alt="Arrow" />
                                        </div>
                                    </SquareCardFooter>
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>

                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-4-card | p-2rem | skills-card"
                                    animate={animateCards}
                                >
                                    <div className="skills-img">
                                        <img src={Skills} alt="" />
                                        <img src={Skills} alt="" />
                                    </div>
                                    <div className="layer"></div>
                                    <SquareCardFooter>
                                        <div className="square-card-footer-text">
                                            <p>TECH STACK</p>
                                            <h2>My Skills</h2>
                                        </div>
                                        <div className="square-card-footer-arrow animated-arrow">
                                            <img src={Arrow1} alt="Arrow" />
                                        </div>
                                    </SquareCardFooter>
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>

                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-4-card | p-2rem"
                                    animate={animateCards}
                                >
                                    <div className="arrow-absolute">
                                        <img src={ArrowGradient} alt="Arrow" />
                                    </div>
                                    <div className="work-together-text-flex | flex justify-between">
                                        <div className="work-together-text">
                                            Let’s work <br />{" "}
                                            <span> together.</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                navigate("/contact");
                                            }}
                                            className="square-card-footer-arrow animated-arrow"
                                        >
                                            <img src={Arrow1} alt="Arrow" />
                                        </div>
                                    </div>
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>

                        <GridRow>
                            <GridRowCol>
                                <HomePageCard
                                    className="size-3-card | p-1rem | social-card"
                                    animate={animateCards}
                                >
                                    <SquareCard>
                                        <div className="square-card-content">
                                            <div className="square-card-content-social">
                                                <div className="square-card-content-social-icons | ">
                                                    <a
                                                        href="https://www.linkedin.com/in/dhairyamarwah/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            src={Linkedin}
                                                            alt="Dhairya's Linkedin"
                                                        />
                                                    </a>

                                                    <a
                                                        href="https://github.com/DhairyaMarwah"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            src={Git}
                                                            alt="Dhairya's Gitub"
                                                        />
                                                    </a>
                                                    <a
                                                        href="https://www.instagram.com/dhairyamarwah_/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            src={Insta}
                                                            alt="Dhairya's instagram"
                                                        />
                                                    </a>

                                                    <a
                                                        href="https://wa.me/7037484499"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            src={Whatsapp}
                                                            alt="Dhairya's whatsapp"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pb-1rem">
                                            <SquareCardFooter>
                                                <div className="square-card-footer-text">
                                                    <p>STAY CONNECTED</p>
                                                    <h2>Profiles</h2>
                                                </div>
                                                <div className="square-card-footer-arrow animated-arrow">
                                                    <img
                                                        src={Arrow1}
                                                        alt="Arrow"
                                                    />
                                                </div>
                                            </SquareCardFooter>
                                        </div>
                                    </SquareCard>
                                </HomePageCard>
                            </GridRowCol>
                        </GridRow>
                    </HomePage>
                    <Footer />
                </>
            )}
        </>
    );
};

export default Index;
