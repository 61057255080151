import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import { createElement, useRef, useState } from "react";
import { ReactComponent as HomeIcon } from "../../assets/dock/home.svg";
import { ReactComponent as ProjectsIcon } from "../../assets/dock/projects.svg";
import { ReactComponent as ChatIcon } from "../../assets/dock/chat.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/dock/linkedin.svg";
import { ReactComponent as MailIcon } from "../../assets/dock/mail.svg";
import { ReactComponent as ResumeIcon } from "../../assets/dock/resume.svg";
import { ReactComponent as GitIcon } from "../../assets/dock/git.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/dock/feedback.svg";
import { useNavigate } from "react-router-dom";

const appIconStyles = {
    width: "60px",
    height: "60px",
    margin: "12px 6px",
};

function AppIcon({ mouseX, icon: Icon, label }) {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    let ref = useRef(null);

    let distance = useTransform(mouseX, (val) => {
        let bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };

        return val - bounds.x - bounds.width / 2;
    });

    let sizeSync = useTransform(distance, [-150, 0, 150], [40, 100, 40]);
    let size = useSpring(sizeSync, { mass: 0.1, stiffness: 150, damping: 12 });

    const handleClick = () => {
        if (label === "home") {
            navigate("/");
        } else if (label === "feedback") {
            navigate("/feedback");
        } else if (label === "projects") {
            navigate("/projects");
        } else if (label === "chat") {
            navigate("/contact");
        } else if (label === "linkedin") {
            window.open("https://www.linkedin.com/in/dhairyamarwah/");
        } else if (label === "mail") {
            window.open("mailto:dhairyamarwag01@gmail.com");
        } else if (label === "experience") {
            navigate("/experience");
        } else if (label === "Github") {
            window.open("https://github.com/DhairyaMarwah");
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <motion.div
                ref={ref}
                style={{
                    ...appIconStyles,
                    width: size,
                    height: size,
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleClick} // Add onClick event handler
            >
                <Icon style={{ width: "120%", height: "120%" }} />
            </motion.div>
            {isHovered && (
                <div
                    style={{
                        position: "absolute",
                        top: "-30px",
                        left: "60%",
                        transform: "translateX(-50%)",
                        backgroundColor: "#2C2A2D",
                        border: "1px solid #4A494C",
                        padding: "4px 12px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        color: "#fff",
                        fontSize: "14px",
                    }}
                >
                    {label}
                </div>
            )}
        </div>
    );
}

const BottomBar = () => {
    const navigate = useNavigate();
    let mouseX = useMotionValue(Infinity);

    return (
        <motion.div
            onMouseMove={(e) => mouseX.set(e.pageX)}
            onMouseLeave={() => mouseX.set(Infinity)}
            style={{
                display: "flex",
                height: "64px",
                transform: "scale(1.18)",
                alignItems: "flex-end",
                justifyContent: "center",
                borderRadius: "16px",
                border: "0.336px solid rgba(255, 255, 255, 0.20)",
                backgroundColor:
                    "linear-gradient(178deg, rgba(47, 47, 47, 0.59) 0%, rgba(33, 33, 33, 0.75) 100%)",
                backdropFilter: "blur(16px)",
                padding: "0 6px",
                width: "fit-content",
                margin: "0 auto",
            }}
        >
            {[
                { icon: HomeIcon, label: "home" },
                { icon: ProjectsIcon, label: "projects" },
                { icon: ChatIcon, label: "chat" },
                { icon: LinkedinIcon, label: "linkedin" },
                { icon: MailIcon, label: "mail" },
                { icon: ResumeIcon, label: "experience" },
                { icon: FeedbackIcon, label: "feedback" },
                { icon: GitIcon, label: "Github" },
            ].map(({ icon, label }) => (
                <div key={label} className="icon-flex">
                    <AppIcon
                        mouseX={mouseX}
                        icon={icon}
                        label={label}
                        key={label}
                    />
                    {window.location.pathname === "/" && label == "home" ? (
                        <>
                            <div className="icon-circle">
                                <div className="icon-circle-img"></div>
                            </div>
                        </>
                    ) : window.location.pathname === "/projects" &&
                      label == "projects" ? (
                        <>
                            <div className="icon-circle">
                                <div className="icon-circle-img"></div>
                            </div>
                        </>
                    ) : window.location.pathname === "/contact" &&
                      label == "chat" ? (
                        <>
                            <div className="icon-circle">
                                <div className="icon-circle-img"></div>
                            </div>
                        </>
                    ) : window.location.pathname === "/experience" &&
                      label == "experience" ? (
                        <>
                            <div className="icon-circle">
                                <div className="icon-circle-img"></div>
                            </div>
                        </>
                    ) : window.location.pathname === "/feedback" &&
                      label == "feedback" ? (
                        <>
                            <div className="icon-circle">
                                <div className="icon-circle-img"></div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            ))}
        </motion.div>
    );
};

export default BottomBar;
