import React from "react";
import Logo from "../../assets/icons/logo.svg";
import "./../../styles/footer/footer.scss";
const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-logo">
                <img src={Logo} alt="" />
            </div>
        </div>
    );
};

export default Footer;
