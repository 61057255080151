export const SubmitForm = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/email/send-email`,
            // 'http://localhost:3001/email/send-email',
            // `https://backend-production-5706.up.railway.app/email/send-email`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            }
        );

        if (!response.ok) {
            throw new Error(" form submission failed");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`form submission failed: ${error.message}`);
    }
};
