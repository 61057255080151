import React, { useState, useEffect } from "react";
import "../../styles/contact/contact.scss";
import Saaspect from "../../assets/carousel/saaspect.svg";
import Graphic from "../../assets/carousel/graphic.svg";
import EstudyPal from "../../assets/carousel/estudypal.svg";
// import S2loop from "../../assets/carousel/s2loop.svg";
// import Unsweetened from "../../assets/carousel/unsweetened.svg";
import Footer from "../../components/Footer";
import ContactConfirmation from "../../modals/FeedBackConfirmation/ContactConfirmation";
import { SubmitForm } from "../../services/ContactForm/contact";

const Index = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        document.title = "Dhairya Marwah | Contact"; // Set the new title
        return () => {
            document.title = "Dhairya Marwah | Developer"; // Restore the previous title when the component unmounts
        };
    }, []);
    const handleSubmit = async (e) => {
        // setShowModal(true);
        e.preventDefault();
        const payload = {
            name,
            email,
            message,
        };
        console.log(payload);
        const res = await SubmitForm(payload);
        console.log(res);
        if (res?.success) {
            setShowModal(true);
        }
    };

    return (
        <>
            <ContactConfirmation
                showModal={showModal}
                setShowModal={setShowModal}
                name={name}
            />
            <div className="contact-page-wrap | max-container | mt-3rem">
                <div className="contact-page-header">
                    <h1>Get In Touch</h1>
                    <p>
                        Interested in working together? We should queue <br />{" "}
                        up a time to chat. I’ll buy the coffee.
                    </p>
                </div>
                <div className="contact-page-flex ">
                    <div className="contact-page-flex-inputs-conatiner">
                        <form onSubmit={handleSubmit}>
                            <div className="contact-page-flex-inputs">
                                <div className="contact-page-flex-input">
                                    <p>Your Name</p>
                                    <input
                                        type="text"
                                        placeholder="Your Name"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        // readOnly={true}
                                        required
                                    />
                                </div>
                                <div className="contact-page-flex-input">
                                    <p>Your Email</p>
                                    <input
                                        type="email"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        // readOnly={true}
                                        required
                                    />
                                </div>
                                <div className="contact-page-flex-input">
                                    <p>Your Message</p>
                                    <textarea
                                        type="text"
                                        placeholder="Your Message"
                                        value={message}
                                        // stop the entering value
                                        // readOnly={true}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <button type="submit">
                                    Let's Talk <span>→</span>
                                    {/* Currently Unavailable */}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="contact-page-flex-qoute-container">
                        <div className="contact-page-flex-qoute">
                            “The genesis of greatness lies within the bond of
                            connection. Collaborate with me to create something
                            extraordinary and leave an indelible legacy."
                        </div>
                        <div className="contact-page-flex-qoute-author">
                            ~ Dhairya Marwah
                        </div>
                        <div className="trusting-companies">
                            <div className="trusting-companies-header">
                                TRUSTED BY ORGANISATIONS LIKE :
                            </div>
                            <div className="companies-carousel">
                                <div className="companies-carousel-list">
                                    <img src={Saaspect} alt="" />
                                    <img src={Graphic} alt="" />
                                    <img src={EstudyPal} alt="" />
                                    <img src={Saaspect} alt="" />
                                    <img src={Graphic} alt="" />
                                    <img src={EstudyPal} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-footer">
                <Footer />
            </div>
        </>
    );
};

export default Index;
