import React, { useState,useEffect } from "react";
import "./../../styles/experience/experience.scss";
import { experiences, achievements } from "../../data/experience";
import Footer from "../../components/Footer";

const Index = () => {
    const [expandedExperience, setExpandedExperience] = useState(null);

    const toggleDescription = (experienceId) => {
        setExpandedExperience((prevValue) =>
            prevValue === experienceId ? null : experienceId
        );
    };

    const isDescriptionExpanded = (experienceId) => {
        return expandedExperience === experienceId;
    };

    const getDescription = (description, experienceId) => {
        const maxLength = isDescriptionExpanded(experienceId) ? Infinity : 600;
        if (description.length > maxLength) {
            return description.slice(0, maxLength) + "...";
        } else {
            return description;
        }
    };

    useEffect(() => {
        document.title = "Dhairya Marwah | Experience"; // Set the new title
        return () => {
            document.title = "Dhairya Marwah | Developer"; // Restore the previous title when the component unmounts
        };
    }, []);

    return (
        <div className="experience-page | max-container mt-4rem">
            <div className="experience-page-header">
                <h1>Work Experience</h1>
            </div>
            <div className="experiences">
                {experiences.map((experience) => {
                    const isExpanded = isDescriptionExpanded(experience.id);
                    return (
                        <div className="experience" key={experience.id}>
                            <div className="circle"></div>
                            <div className="experience-info">
                                <div className="experience-role">
                                    {experience.role}
                                </div>
                                <div className="experience-company">
                                    {experience.company}
                                </div>
                                <div className="experience-duration">
                                    {experience.duration}
                                </div>
                            </div>
                            <div className="experience-desc-wrap">
                                <div
                                    className="experience-desc"
                                    dangerouslySetInnerHTML={{
                                        __html: getDescription(
                                            experience.description,
                                            experience.id
                                        ),
                                    }}
                                ></div>
                                {experience.description.length > 600 && (
                                    <button
                                        className="view-more-button"
                                        onClick={() =>
                                            toggleDescription(experience.id)
                                        }
                                    >
                                        {isExpanded
                                            ? "View Less"
                                            : "View More ..."}
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="experience-page-header">
                <h1>Achievments</h1>
            </div>
            <div className="experiences">
                {achievements.map((achievement) => {
                    const isExpanded = isDescriptionExpanded(achievement.id);
                    return (
                        <div className="experience" key={achievement.id}>
                            <div className="circle"></div>
                            <div className="experience-info">
                                <div className="experience-role">
                                    {achievement.achievement}
                                </div>
                                <div className="experience-company">
                                    {achievement.organization}
                                </div>
                                <div className="experience-duration">
                                    {achievement.duration}
                                </div>
                            </div>
                            <div className="experience-desc-wrap">
                                <div
                                    className="experience-desc"
                                    dangerouslySetInnerHTML={{
                                        __html: getDescription(
                                            achievement.description,
                                            achievement.id
                                        ),
                                    }}
                                ></div>
                                {achievement.description.length > 600 && (
                                    <button
                                        className="view-more-button"
                                        onClick={() =>
                                            toggleDescription(achievement.id)
                                        }
                                    >
                                        {isExpanded
                                            ? "View Less"
                                            : "View More ..."}
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="contact-footer">
                <Footer />
            </div>
        </div>
    );
};

export default Index;
