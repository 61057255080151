import React from "react";
import "./styles/global/global.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./routes/routes";
import ReactGa from "react-ga";
import { Analytics } from "@vercel/analytics/react";

const TrackingId = "UA-276439082-1";
ReactGa.initialize(TrackingId);
function App() {
    return (
        <BrowserRouter>
            <ToastContainer />
            <Navigation />
            <Analytics />
        </BrowserRouter>
    );
}

export default App;
