import React, { useState, useEffect } from "react";
import ProjectText from "../../assets/others/projectstext.svg";
import { motion } from "framer-motion";
import { projects, mobileProjects } from "../../data/projects.js";
import "./../../styles/grids/projectGrid/projectGrid.scss";
import ProjectCard from "../../components/Cards/ProjectCard";
import Footer from "../../components/Footer";

import ReactGa from "react-ga";

const ProjectPage = ({ children }) => (
    <div className="home-page">
        <div className="home-page-grid | project-page-grid | max-container mt-rem">
            {children}
        </div>
    </div>
);

const GridRow = ({ children }) => (
    <div className="home-page-grid-row | ">{children}</div>
);

const GridRowCol = ({ children }) => (
    <div className="home-page-grid-row-col">{children}</div>
);

const HomePage = ({
    showModal,
    setShowModal,
    projectsdata,
    setprojectsdata,
}) => {
    const [visibleProjects, setVisibleProjects] = useState(3);
    useEffect(() => {
        ReactGa.pageview(window.location.pathname);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.innerHeight + window.pageYOffset;
            const pageHeight = document.documentElement.scrollHeight;
            const offset = 200;

            if (scrollPosition >= pageHeight - offset) {
                setVisibleProjects(
                    (prevVisibleProjects) => prevVisibleProjects + 2
                );
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const tabs = [
        { id: "All", label: "All" },
        { id: "web", label: "Web" },
        { id: "design", label: "Design" },
        { id: "UI/UX", label: "UI/UX" },
        { id: "branding", label: "Branding" },
        { id: "Web3", label: "Web3" },
        { id: "app", label: "App" },
        { id: "machineLearning", label: "Machine Learning" },
        { id: "popular", label: "Popular" },
    ];

    const [selectedFilter, setSelectedFilter] = useState(tabs[0].id);
    const [animateCards, setAnimateCards] = useState(false);

    useEffect(() => {
        setAnimateCards(true);
    }, [selectedFilter]);

    useEffect(() => {
        document.title = "Dhairya Marwah | Projects"; // Set the new title
        return () => {
            document.title = "Dhairya Marwah | Developer"; // Restore the previous title when the component unmounts
        };
    }, []);

    const [isMobileView, setIsMobileView] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1000) {
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Check initial window size

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <>
            <ProjectPage>
                {isMobileView ? (
                    <>
                        <GridRowCol>
                            <img
                                className="projectText"
                                src={ProjectText}
                                alt=""
                            />
                        </GridRowCol>
                        <GridRow>
                            <GridRowCol>
                                {mobileProjects &&
                                    mobileProjects
                                        .filter((project) =>
                                            selectedFilter === "All"
                                                ? true
                                                : project.category.includes(
                                                      selectedFilter
                                                  )
                                        )
                                        .slice(0, visibleProjects)
                                        .map((project, index) => {
                                            return (
                                                <ProjectCard
                                                    animate={animateCards}
                                                    key={index}
                                                    project={project}
                                                    showModal={showModal}
                                                    setShowModal={setShowModal}
                                                    projectsdata={projectsdata}
                                                    setprojectsdata={
                                                        setprojectsdata
                                                    }
                                                />
                                            );
                                        })}
                            </GridRowCol>
                        </GridRow>
                        <Footer />
                    </>
                ) : (
                    <>
                        <div className="pill-container flex | mb-2rem">
                            {tabs.map((tab) => (
                                <div
                                    key={tab.id}
                                    onClick={() => {
                                        setSelectedFilter(tab.id);
                                    }}
                                    className={`pill-animation  ${
                                        selectedFilter === tab.id
                                            ? ""
                                            : "hover:text-white/60"
                                    } relative rounded-full px-3  outline-sky-400 transition focus-visible:outline-2`}
                                    style={{
                                        WebkitTapHighlightColor: "transparent",
                                    }}
                                >
                                    {selectedFilter === tab.id && (
                                        <motion.span
                                            layoutId="bubble"
                                            className="absolute inset-0 z-10 bg-white mix-blend-difference"
                                            style={{ borderRadius: 9999 }}
                                            transition={{
                                                type: "spring",
                                                bounce: 0.2,
                                                duration: 0.6,
                                            }}
                                        ></motion.span>
                                    )}
                                    {tab.label}
                                </div>
                            ))}
                        </div>
                        <GridRow>
                            <GridRowCol>
                                {projects &&
                                    projects.row1
                                        .filter((project) =>
                                            selectedFilter === "All"
                                                ? true
                                                : project.category.includes(
                                                      selectedFilter
                                                  )
                                        )
                                        .slice(0, visibleProjects)
                                        .map((project, index) => (
                                            <ProjectCard
                                                animate={animateCards}
                                                key={index}
                                                project={project}
                                                showModal={showModal}
                                                setShowModal={setShowModal}
                                                projectsdata={projectsdata}
                                                setprojectsdata={
                                                    setprojectsdata
                                                }
                                            />
                                        ))}
                            </GridRowCol>
                            <GridRowCol>
                                <GridRowCol>
                                    <img
                                        className="projectText"
                                        src={ProjectText}
                                        alt=""
                                    />
                                </GridRowCol>
                                <GridRow>
                                    {projects && (
                                        <>
                                            <GridRowCol>
                                                {projects.row2
                                                    .filter((project) =>
                                                        selectedFilter === "All"
                                                            ? true
                                                            : project.category.includes(
                                                                  selectedFilter
                                                              )
                                                    )
                                                    .slice(0, visibleProjects)
                                                    .map((project, index) => (
                                                        <ProjectCard
                                                            animate={
                                                                animateCards
                                                            }
                                                            key={index}
                                                            project={project}
                                                            showModal={
                                                                showModal
                                                            }
                                                            setShowModal={
                                                                setShowModal
                                                            }
                                                            projectsdata={
                                                                projectsdata
                                                            }
                                                            setprojectsdata={
                                                                setprojectsdata
                                                            }
                                                        />
                                                    ))}
                                            </GridRowCol>
                                            <GridRowCol>
                                                {projects.row3
                                                    .filter((project) =>
                                                        selectedFilter === "All"
                                                            ? true
                                                            : project.category.includes(
                                                                  selectedFilter
                                                              )
                                                    )
                                                    .slice(0, visibleProjects)
                                                    .map((project, index) => (
                                                        <ProjectCard
                                                            animate={
                                                                animateCards
                                                            }
                                                            key={index}
                                                            project={project}
                                                            showModal={
                                                                showModal
                                                            }
                                                            setShowModal={
                                                                setShowModal
                                                            }
                                                            projectsdata={
                                                                projectsdata
                                                            }
                                                            setprojectsdata={
                                                                setprojectsdata
                                                            }
                                                        />
                                                    ))}
                                            </GridRowCol>
                                        </>
                                    )}
                                </GridRow>
                            </GridRowCol>
                        </GridRow>
                    </>
                )}
            </ProjectPage>
        </>
    );
};

export default HomePage;
