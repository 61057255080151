export const SubmitFeedback = async (formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/feedback/postFeedback`,
            // "http://localhost:3001/feedback/postFeedback",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            }
        );

        if (!response.ok) {
            throw new Error(" form submission failed");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`form submission failed: ${error.message}`);
    }
};

export const GetFeedbacks = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/feedback/getFeedbacks`,
            // "http://localhost:3001/feedback/postFeedback",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(" form submission failed");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`form submission failed: ${error.message}`);
    }
};
