import React from "react";
import ArrowDull from "../../assets/icons/arrow-dull.svg";
import Arrow1 from "../../assets/iconanimation/arrow.svg";
const ProjectCard = ({
    animate,
    project,
    showModal,
    setShowModal,
    projectsdata,
    setprojectsdata,
}) => {
    return (
        <>
            <div
                onClick={() => {
                    setShowModal(true);
                    setprojectsdata(project);
                }}
                className={animate ? "ProjectCard animate" : "ProjectCard"}
            >
                <div className="ProjectCardImage">
                    <img src={project.projectImage} alt="" />
                </div>
                <div className="ProjectCardContentFlex | flex justify-between">
                    <div className="ProjectCardContent">
                        <div className="ProjectCardContent-desc">
                            {project.projectDesc}
                        </div>
                        <div className="ProjectCardContent-name">
                            {project.projectName}
                        </div>
                    </div>
                    {/* <div className="ProjectCardContentArrow">
                        <img src={ArrowDull} alt="" />
                    </div> */}
                    <div className="square-card-footer-arrow animated-arrow">
                        <img src={Arrow1} alt="Arrow" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectCard;
