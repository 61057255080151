import React, { useState, useEffect } from "react";
import Logo from "../../assets/icons/logo.svg";
import Write from "../../assets/icons/write.svg";
import "./../../styles/navbar/navbar.scss";
import { useNavigate, useLocation } from "react-router-dom";

const Index = ({ showModal, setShowModal }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [strechNavbar, setStrechNavbar] = useState(false);
    const [isWriteButtonActive, setIsWriteButtonActive] = useState(true);

    useEffect(() => {
        if (location.pathname === "/feedback") {
            setStrechNavbar(true);
        } else {
            setStrechNavbar(false);
        }
    }, [location]);

    useEffect(() => {
        const timer = setInterval(
            () => {
                // setIsWriteButtonActive((prev) => !prev);
                setIsWriteButtonActive(true);
            },
            isWriteButtonActive ? 3000 : 10000
        );

        return () => clearInterval(timer);
    }, [isWriteButtonActive]);

    return (
        <div
            className={`navbar max-container | mt-3rem ${
                strechNavbar ? "strech-navbar" : ""
            }`}
        >
            <div
                onClick={() => {
                    navigate("/");
                }}
                className="navbar-logo"
            >
                <img src={Logo} alt="logo" />
            </div>
            {/* <div className="nav-btns">
                <button
                    onClick={() => {
                        navigate("/contact");
                    }}
                    className={`type-1-button | button | secondary-button ${
                        isWriteButtonActive ? "write-active" : "talk-active"
                    }`}
                >
                    <span className="text-transition">Let's Talk</span>
                </button>
            </div> */}
            {/* <div className="nav-btns">
                <button>
                    <span className="text-transition">Let's Talk</span>
                </button>
            </div> */}
            <div className="nav-btns">
                <button
                    className={`type-1-button | button | secondary-button ${
                        isWriteButtonActive ? "write-active" : "talk-active"
                    }`}
                    onClick={() => {
                        if (isWriteButtonActive) {
                            setShowModal((prev) => !prev);
                        } else {
                            navigate("/contact");
                        }
                    }}
                >
                    {isWriteButtonActive ? (
                        <>
                            <span className="text-transition">
                                Write a Feedback
                            </span>
                            <img src={Write} alt="" />
                        </>
                    ) : (
                        <span className="text-transition">Let's Talk</span>
                    )}
                </button>
            </div>
        </div>
    );
};

export default Index;
