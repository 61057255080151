import React, {useState, useEffect } from "react";
import "./../../styles/Feedback/feedback.scss";
// import { feedback } from "../../data/feedback";
import Initial from "../../assets/icons/initials.svg";
import Avatar1 from "../../assets/icons/avatar1.png";
import Avatar2 from "../../assets/icons/avatar2.png";
import Avatar3 from "../../assets/icons/avatar3.png";
import Avatar4 from "../../assets/icons/avatar4.png";
import { GetFeedbacks } from "../../services/FeedBack/feedback";
const Index = () => {
    const [feedbacks, setfeedbacks] = useState()
    useEffect(() => {
        const fetchFeedbacks = async () => {
            const res = await GetFeedbacks();
            setfeedbacks(res?.feedbacks);
            console.log(res);
        };
        fetchFeedbacks();
    }, []);
    return (
        <div className="feed-back-page | strech-navbar | mt-3rem | feedback-container">
            <h1>Your Feedbacks</h1> 
            <div className="feedback-cards">
                <div className="feedback-cards-list">
                    {feedbacks?.map((item, index) => {
                        return (
                            <div className="feedback-card" key={index}>
                                <div className="feedback-card-block">
                                    <div className="feed-back-logo">
                                        <img src={Initial} alt="" />
                                    </div>
                                    <div className="feedback">
                                        {item.feedback.substring(0, 160)}...
                                    </div>
                                </div>
                                <div className="feedback-name">
                                    <img
                                        src={
                                            item.imgType === "type1"
                                                ? Avatar1
                                                : item.imgType === "type2"
                                                ? Avatar2
                                                : item.imgType === "type3"
                                                ? Avatar3
                                                : Avatar4
                                        }
                                        alt=""
                                    />
                                    <div className="feedback-name-info">
                                        <div className="feedback-name-info-name">
                                            {item.name}
                                        </div>
                                        <div className="feedback-name-info-date">
                                            {item.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {feedbacks?.map((item, index) => {
                        return (
                            <div className="feedback-card" key={index}>
                                <div className="feedback-card-block">
                                    <div className="feed-back-logo">
                                        <img src={Initial} alt="" />
                                    </div>
                                    <div className="feedback">
                                    {item.feedback.substring(0, 160)}...
                                    </div>
                                </div>
                                <div className="feedback-name">
                                    <img
                                        src={
                                            item.imgType === "type1"
                                                ? Avatar1
                                                : item.imgType === "type2"
                                                ? Avatar2
                                                : item.imgType === "type3"
                                                ? Avatar3
                                                : Avatar4
                                        }
                                        alt=""
                                    />
                                    <div className="feedback-name-info">
                                        <div className="feedback-name-info-name">
                                            {item.name}
                                        </div>
                                        <div className="feedback-name-info-date">
                                            {item.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="feedback-cards-list | feedback-cards-list2">
                    {feedbacks?.map((item, index) => {
                        return (
                            <div className="feedback-card" key={index}>
                                <div className="feedback-card-block">
                                    <div className="feed-back-logo">
                                        <img src={Initial} alt="" />
                                    </div>
                                    <div className="feedback">
                                    {item.feedback.substring(0, 160)}...
                                    </div>
                                </div>
                                <div className="feedback-name">
                                    <img
                                        src={
                                            item.imgType === "type1"
                                                ? Avatar1
                                                : item.imgType === "type2"
                                                ? Avatar2
                                                : item.imgType === "type3"
                                                ? Avatar3
                                                : Avatar4
                                        }
                                        alt=""
                                    />
                                    <div className="feedback-name-info">
                                        <div className="feedback-name-info-name">
                                            {item.name}
                                        </div>
                                        <div className="feedback-name-info-date">
                                            {item.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {feedbacks?.map((item, index) => {
                        return (
                            <div className="feedback-card" key={index}>
                                <div className="feedback-card-block">
                                    <div className="feed-back-logo">
                                        <img src={Initial} alt="" />
                                    </div>
                                    <div className="feedback">
                                    {item.feedback.substring(0, 160)}...
                                    </div>
                                </div>
                                <div className="feedback-name">
                                    <img
                                        src={
                                            item.imgType === "type1"
                                                ? Avatar1
                                                : item.imgType === "type2"
                                                ? Avatar2
                                                : item.imgType === "type3"
                                                ? Avatar3
                                                : Avatar4
                                        }
                                        alt=""
                                    />
                                    <div className="feedback-name-info">
                                        <div className="feedback-name-info-name">
                                            {item.name}
                                        </div>
                                        <div className="feedback-name-info-date">
                                            {item.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Index;
